import React, { useContext, useEffect, useRef, useState } from "react";

import './css/issue.css'
import { useParams } from "react-router-dom";
import { ChatConfirm, ChatFunnel, ChatNegativeSentiments, ChatTimeSentiments } from "./snippet/ChatDisplay";
import { ChatAnalysis } from "./snippet/ChatAnalysis";
import { apiFetch } from "./snippet/API";
import { sio } from "./snippet/socket";
import { MessagesContext } from "./snippet/MessagesContext";

export function DashboardPage() {
    const [whichAnalysis, setWhichAnalysis] = useState(0)
    const [translate, setTranslate] = useState('translate(0px,28px)')
    const [roomId, setRoomId] = useState(-1)
    
    const { setMessages } = useContext(MessagesContext)

    const [isResolve, setIsResolve] = useState(false)
    const [resolveCnt, setResolveCnt] = useState(0)

    const [os, setOS] = useState('');

    const { demo } = useParams()

    const setAnalysis = (id) => {
        if(id=='radioTime') {
            setTranslate('translate(0,28px)')
            setWhichAnalysis(0)
        } else if(id == 'radioSentiment') {
            setTranslate('translate(69px,28px) scaleX(1.3)')
            setWhichAnalysis(1)
        } else if(id == 'radioSubject') {
            setTranslate('translate(151px, 28px) scaleX(1)')
            setWhichAnalysis(2)
        } else if(id == 'radioFunnel') {
            setTranslate('translate(144px, 28px) scaleX(1.3)')
            setWhichAnalysis(3)
        } else if(id == 'radioBuy') {
            setTranslate('translate(247px, 28px) scaleX(2.4)')
            setWhichAnalysis(4)
        } else if(id == 'radioHazard') {
            setTranslate('translate(289px, 28px) scaleX(1.2)')
            setWhichAnalysis(5)
        }
    }

    const detectOS = () => {
        const platform = navigator.platform.toLowerCase();
        if (platform.indexOf('mac') !== -1) {
            return 'mac';
        } else if (platform.indexOf('win') !== -1) {
            return 'windows';
        } else if (platform.indexOf('linux') !== -1) {
            return 'linux';
        }
        return 'unknown';
    };

    //set socket event
    useEffect(()=>{
        //console.log(sio, demo)
        sio.emit('user_connection', {sauceflex_id: demo})
        sio.on('new_chat', (msg) => {
            setMessages((prev)=>{
                return [msg, ...prev]
            })
            /*if( !msg.is_manager ) {
            }*/
            //const status = apiFetch(`/${roomId}/clusters/?window_size=${clusterSize}`, [], 'POST')
        })

        sio.on('chat_status', (chat) => {
            setMessages((prev)=>{
                const index = prev.findIndex((element) => element.id === chat.id);
                const con = [...prev];
                if(index != -1) {
                    con[index].is_resolved = chat.is_resolved
                    //console.log(con)
                }
                return con
            })
            setResolveCnt((prev)=>++prev)
        })

        return ()=>{
            sio.off('new_chat')
            sio.off('chat_status')
            sio.disconnect()
        }
    },[])

    const countRef = useRef(0);
    const intervalRef = useRef(null);

    //set roomId and message
    useEffect(() => {
        const fetchData = async () => {
            //console.log(countRef.current)
            if(countRef.current >= 1 || roomId != -1) {clearInterval(intervalRef.current)}
            else {
                try {
                    const result = await apiFetch('/rooms/', null, 'GET');
                    result.forEach(async (room)=>{
                        if(room.subject == demo) {
                            setRoomId(room.id)
                            //console.log(room)
                            
                            const data = await apiFetch(`/${room.id}/chats/`)
                            setMessages(data.reverse())
                        }
                    })
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
            countRef.current += 1;
        };
    
        intervalRef.current = setInterval(fetchData, 1000)

        const osType = detectOS();
        setOS(osType)

        return (()=>{
            clearInterval(intervalRef.current)
        })
      }, []);
    

    return (
        <div className="info" id="liveComment">
            <ChatAnalysis roomId = {roomId}/>
            <div className="checkbar">
                <div className={"radioBox " + os}>
                    <input className="radio" type="radio" id="radioTime" name="radioTime" checked={whichAnalysis == 0}/>
                    <label for="radioTime" onClick={() => {setAnalysis('radioTime')}}>시간 순</label>

                    <input className="radio" type="radio" id="radioSentiment" name="radioSentiment" checked={whichAnalysis == 1}/>
                    <label for="radioSentiment" onClick={() => {setAnalysis('radioSentiment')}}>부정도 순</label>

                    <input className="radio" type="radio" id="radioFunnel" name="radioFunnel" checked={whichAnalysis == 3}/>
                    <label for="radioFunnel" onClick={() => {setAnalysis('radioFunnel')}}>퍼널 분석</label>

                    <input className="radio" type="radio" id="radioBuy" name="radioBuy" checked={whichAnalysis == 4}/>
                    <label for="radioBuy" onClick={() => {setAnalysis('radioBuy')}}>이벤트 참여자 조회</label>

                    <div id="checkedLine" style={{position: 'absolute', width: '54px',height: '2px',backgroundColor: '#0a093d',transition: '0.5s all ease',transform:translate}}></div>
                </div>
                {/*<div className={"checkBox "+os}>
                    <div style={{marginRight: '5px'}}>답변한 문의 숨김</div>
                    <input type="checkbox" id="isResolve" name="isResolve"/>
                    <label for="isResolve" onClick={()=>{setIsResolve((prev)=>!prev)}}></label>
                </div>*/}
            </div>
            <div className="contentBox">
                { whichAnalysis == 0 ? <ChatTimeSentiments roomId = {roomId} isResolve={isResolve} setResolveCnt={(e)=>{setResolveCnt(e)}}/> 
                : whichAnalysis == 1 ? <ChatNegativeSentiments roomId = {roomId} isResolve={isResolve} setResolveCnt={(e)=>{setResolveCnt(e)}}/>
                : whichAnalysis == 3 ? <ChatFunnel roomId = {roomId} isResolve={isResolve}/>
                : <ChatConfirm roomId = {roomId}/>}
            </div>
        </div>
    )
}