import React, {useContext, useEffect, useRef, useState} from 'react';
import { apiFetch } from './API';
import { SentimentChart } from './drawGraph';
import { Comment } from './Chat';
import { MessagesContext } from './MessagesContext';
import { useLocation } from 'react-router-dom';


export function ChatAnalysis({roomId}) {
    const [totalComment, setTotalComment] = useState(-1)
    const [answeredComment, setAnsweredComment] = useState(-1)
    const [positivePercent, setPositivePercent] = useState(0)
    const [init, setInit] = useState([0,0,0,0,0,0])
    const [xData, setXData] = useState(['', '', '', '', '', ''])

    const [forceUpdate, setForceUpdate] = useState(0); // 강제 리렌더링을 위한 상태 추가

    const {messages} = useContext(MessagesContext)
    
    const statusInterval = useRef(null);
    const currentTime = useRef(null)

    const location = useLocation()

    useEffect(() => { 
        async function data(roomId) {
            const analy = await apiFetch(`/${roomId}/status/`)
            setTotalComment(analy.total_cnt) 
            setAnsweredComment(analy.all_inquiries)
            
            //console.log(analy)

            const LiveState = await apiFetch(`/sauce/getRoom/?sauceflex_id=${getRoomStatus()}`)

            let lastMessageTime = new Date()

            if(LiveState.room_state == 1 || LiveState.room_state == 0) {
                if (statusInterval.current) {
                    console.log('현재 실행 중인 interval ID:', statusInterval.current); // 현재 interval ID 출력
                    clearInterval(statusInterval.current); // 이전 타이머 정리
                }
                statusInterval.current = setInterval(()=>checkTimeAndExecute(), 1000)
            } else if(LiveState.room_state == 3) {
                lastMessageTime = new Date(analy.latest_timestamp);
            }
            const minutesToSubtract = 6 * 10;
            currentTime.current = new Date(lastMessageTime.getTime() - minutesToSubtract * 60000);
            let tmpList = []
            for(let i = 0 ; i < 6 ; i++) {
                tmpList.push(getHourMin());
            }

            setInit(analy.sentiment_avg)
            setPositivePercent((analy.sentiment_avg[analy.sentiment_avg.length - 1]).toFixed(1))
            setXData(tmpList)
        }

        function checkTimeAndExecute() {
            const now = new Date();
            const minutes = now.getMinutes()
        
            //console.log(currentTime.current.getMinutes(), minutes)
    
            if ([0, 10, 20, 30, 40, 50].includes(minutes) && currentTime.current.getMinutes() / 10 != minutes / 10) {
                //console.log('success')
                set(roomId);
            }
        }

        if(roomId != undefined && roomId != -1) {
            data(roomId)
        }

        return (()=>{
            clearInterval(statusInterval.current)
        })
    },[roomId])

    useEffect(()=>{
        if(messages != undefined && messages.length != totalComment && messages.length > 0) {
            if(messages.length > 0) {
                setTotalComment((prev)=>messages.length);
                const answeredCount = messages.filter(message => message.type === 1).length;
                setAnsweredComment(answeredCount);
                setPositivePercent((prev)=>(prev*(messages.length-1)+messages[0].sentiment)/messages.length)
            }
        }
        //console.log(totalComment)
    }, [messages.length])

    async function set(roomId) {
        try {
            const analy = await apiFetch(`/${roomId}/status/`)
            
            setInit((prev)=>analy.sentiment_avg)
            setPositivePercent((analy.sentiment_avg[analy.sentiment_avg.length - 1]).toFixed(1))

            const nextTimeText = getHourMin()
            setXData((prev) => {
                let cur = [...prev]
                cur.shift()
                cur.push(nextTimeText)
                return cur
            })

            // 강제 리렌더링을 위해 forceUpdate 상태 업데이트
            setForceUpdate((prev) => prev + 1); // 상태를 업데이트하여 리렌더링 유도
        } catch (e) {
            console.log('[Status Error] ' + e);
        }
    }

    function getNextScheduledTime(startTime) {
        const minutes = startTime.getMinutes();
        const nextMinuteMark = [0, 10, 20, 30, 40, 50].find(minute => minute > minutes);
        //console.log(minutes)
        if (nextMinuteMark !== undefined) {
            return new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate(), startTime.getHours(), nextMinuteMark, 0, 0);
        } else {
            // 다음 시간이 다음 시간의 첫 0분이라면
            const nextHour = new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate(), startTime.getHours() + 1, 0, 0, 0);
            return nextHour;
        }
    }

    function getHourMin() {
        currentTime.current = getNextScheduledTime(currentTime.current)
        let hours = ('0' + currentTime.current.getHours()).slice(-2);
        let minutes = ('0' + currentTime.current.getMinutes()).slice(-2);
        return `${hours}:${minutes}`;
    }

    const getRoomStatus = () => {
        const pathSegments = location.pathname.split('/'); // 경로를 '/'로 분리
        const dashboardIndex = pathSegments.indexOf('dashboard'); // 'dashboard'의 인덱스 찾기
        return dashboardIndex !== -1 && dashboardIndex + 1 < pathSegments.length 
            ? pathSegments[dashboardIndex + 1] // 'dashboard' 뒤의 값 반환
            : null; // 값이 없을 경우 null 반환
    }

    return (
        <div className="summaries">
            <div className="summary">
                <div className="summaryTitle">전체 채팅</div>
                <div className="summaryContent" id="totalComment">{(totalComment < 0 ? 0 : totalComment) + '개'}</div>
            </div>
            <div className="summary">
                <div className="summaryTitle">문의 채팅</div>
                <div className="summaryContent" id="answeredComment">{(answeredComment < 0 ? 0 : answeredComment) + '개'}</div>
            </div>
            <div className="summary">
                <div className="summaryTitle">최근 채팅 감정<Comment cmt='sentiment'/></div>
                <div className="summaryContent" id="liveSentiment" style={{transform: 'translate(-20px, -20px)'}}><SentimentChart key={JSON.stringify(init)} sentiment={init} xData={xData}/></div>
            </div>
            <div className="summary">
                <div className="summaryTitle">긍정도<Comment cmt='positive'/></div>
                <div className="summaryContent" id="positivePercent">{(positivePercent*100).toFixed(1) + '%'}</div>
            </div>
        </div>
    )
}